import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"
import {Container} from 'react-bootstrap'

const TermsConditions = () => (
  <>
    <Header />
    <SEO title="Terms and Conditions" />
    <Container className="mt-2">
      <h1>Terms and Conditions for the Use of the Opsmorph Website</h1>
      <hr />
      <h4>Introduction</h4>
      <p>These Terms and Conditions govern your use of the Opsmorph Website at opsmorph.com. By continuing to use the Opsmorph Website you agree to these Terms and Conditions. Should you not agree to these Terms and Conditions you are not permitted to use the Opsmorph Website.</p> 
      <h4>Right to make changes to the agreement</h4>
      <p>Opsmorph Limited reserve the right to modify these Terms and Conditions at any time. It is your responsibility as a user of the Opsmorph Website to check these Terms and Conditions regularly.</p> 
      <h4>User Restrictions</h4>
      <p>Users of this website are explicitly restricted from:</p>
      <ol>
          <li>Publishing, selling, or commercializing content from this website in any other media without the consent of Opsmorph Limited. This does not restrict users of this website from sharing links to this website on social media.</li> 
          <li>Using the Opsmorph Website in any way that is damaging to the website or that restricts users access to the Opsmorph Website.</li> 
      </ol>
      <h4>Copyright and intellectual property</h4>
      <p>Unless otherwise stated, all logos, company names, domain names, source code and content presented on this website is the intellectual property of Opsmorph Limited.</p> 
      <h4>Governing law</h4> 
      <p>The use of the Opsmorph Website is governed by and subject to the law of the United Kingdom as the country under which Opsmorph Limited operates this website.</p> 
      <h4>Warranty disclaimer</h4>
      <p>All content and functionality on the Opsmorph Website are presented as is and Opsmorph Limited and its Directors express no representation or warranty to anything on the Opsmorph Website. Nothing on the Opsmorph Website should be interpreted as Opsmorph Limited or any of its Directors advising you the user.</p> 
      <h4>Limitation of liability</h4>
      <p>This website contains links to third-party websites, and it is the responsibility of you the user of the Opsmorph Website to check the Terms and Conditions of those websites when using them. Opsmorph Limited and its Directors accept no liability for the content or functionality of third-party websites linked to from the Opsmorph Website. It is your responsibility to maintain your antivirus software and Opsmorph Limited accepts no responsibility for damage occurred while browsing the Opsmorph Website or the third-party websites linked to from the Opsmorph Website.</p> 
      <h4>Last updated</h4> 
      <p>These Terms and Conditions were last updated on 7 November 2020.</p>
    </Container>
    <Footer />
  </>
)

export default TermsConditions